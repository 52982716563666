import React, { useState, cloneElement, useEffect } from 'react'
import PropTypes from 'prop-types'
import Router, { useRouter } from 'next/router'
import { useIntercom } from 'react-use-intercom'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import Bugsnag from '@bugsnag/js'
import getConfig from 'next/config'

import { useSubscription, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { MainContainer, AppWrapper } from '../layouts.styles'
import EditorHeader from '../../components/EditorHeader'
import TemplatePreviewDialog from '../../components/TemplatePreviewDialog'
import {
  USER_TEMPLATE_RENDER_STATUS_SUBSCRIPTION,
  updateTemplateUrlUponRenderMutation
} from '../../graphql/userTemplates'
import RouteGuard from './RouteGuard'

const {
  publicRuntimeConfig: { BUGSNAG_API_KEY }
} = getConfig()

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

// TODO: consider finding another solution for toggling header & footer

const Layout = ({ children, user }) => {
  const router = useRouter()

  const { boot, trackEvent } = useIntercom()
  const [showFooter, setShowFooter] = useState(false)
  const [showHeader, setShowHeader] = useState(false)
  const [light, setLightTheme] = useState(false)
  const [editorHeader, setEditorHeader] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [updateUrl] = useMutation(updateTemplateUrlUponRenderMutation)

  useSubscription(USER_TEMPLATE_RENDER_STATUS_SUBSCRIPTION, {
    variables: {
      userId: user && user.id
    },
    skip: !user,
    onSubscriptionData: (options) => {
      const {
        node: {
          status,
          userTemplate: { id, name, type }
        }
      } = options.subscriptionData.data.userTemplateRenderStatusUpdated

      if (status === 'SUCCESSFUL' && type?.typeName === 'UserVideoTemplate') {
        updateUrl({
          variables: {
            id
          }
        })
      }
      const variant = status === 'SUCCESSFUL' ? 'success' : 'error'
      const message =
        status === 'SUCCESSFUL' ? 'Rendered Successfully!' : 'Render Failed'
      if (['SUCCESSFUL', 'FAILED'].includes(status)) {
        trackEvent(status === 'SUCCESSFUL' ? 'render-success' : 'render-fail', {
          id
        })

        if (type.displayName !== 'User Image')
          enqueueSnackbar(
            <Box
              whiteSpace='nowrap'
              overflow='hidden'
              textOverflow='ellipsis'
              width={300}
            >
              {name}
              <Typography>{message}</Typography>
              <Link color='inherit' href={`/templates/watch/${id}`}>
                Check it here!
              </Link>
            </Box>,
            {
              variant
            }
          )
      }
    }
  })

  const childrenWithProps = cloneElement(children, {
    setShowFooter,
    setShowHeader,
    setLightTheme,
    setEditorHeader
  })

  useEffect(() => {
    // Boot intercom
    const {
      firstName,
      lastName,
      id,
      email,
      agency,
      business,
      salesAccount,
      createdAt,
      username,
      plan,
      extras,
      affiliateEmail,
      affiliateName
    } = user || {}

    let extrasString = ''
    let extrasString2 = ''
    for (let i = 0; i < extras?.length; i += 1) {
      const extraName = extras?.[i]?.subscriptionParent?.name
      const newString = extrasString
        ? `${extrasString},${extraName}`
        : extraName
      if (newString.length > 255) {
        extrasString2 = extrasString2
          ? `${extrasString2},${extraName}`
          : extraName
      } else {
        extrasString = newString
      }
    }
    if (user) {
      Bugsnag.setUser(id, email, `${firstName} ${lastName}`)
      boot({
        name: `${firstName} ${lastName}`,
        userId: id,
        email,
        createdAt,
        customAttributes: {
          portal: true,
          has_agency: !!agency,
          has_business: !!business,
          has_sales_account: !!salesAccount,
          username,
          plan: plan?.subscriptionParent?.name,
          extras: extrasString,
          extras2: extrasString2,
          affiliate_email: affiliateEmail,
          affiliate_name: affiliateName
        }
      })
    }
  }, [user])

  const HotjarScript = `
  (function(h,o,t,j,a,r){
      h['hj']=h['hj']||function(){(h['hj'].q=h['hj'].q||[]).push(arguments)};
      h['_hjSettings']={hjid:3394006,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h['_hjSettings'].hjid+j+h['_hjSettings'].hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
`

  useEffect(() => {
    const script = document.createElement('script')
    script.innerHTML = HotjarScript
    document.head.appendChild(script)
  }, [])

  return (
    <RouteGuard user={user}>
      <ErrorBoundary>
        <div>
          <AppWrapper>
            {!editorHeader ? (
              showHeader && <Header user={user} light={light} />
            ) : (
              <EditorHeader user={user} template={editorHeader} />
            )}
            <MainContainer>{childrenWithProps}</MainContainer>
            {showFooter && <Footer />}
            <TemplatePreviewDialog
              open={!!router.query.dialogTemplateId}
              onClose={() => Router.back()}
            />
          </AppWrapper>
        </div>
      </ErrorBoundary>
    </RouteGuard>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object.isRequired
}

export default Layout
